import type { ArticleColumn } from '@/types/article-column.type';
import type { ArticleStatus } from '@/types/article-status.enum';
import type { Grouping } from '@/types/grouping.enum';
import type { TailOptions } from '@/types/tail-options.interface';

const toQueryParamList = <T>(list: T[]): string => {
  return encodeURIComponent(list.join(','));
};

export const buildQueryString = ({
  retailUnitCode,
  fiscalYear,
  articleStatus,
  grouping,
  groupNo,
  tailOptions,
  column,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
}: {
  retailUnitCode?: string;
  fiscalYear?: string;
  articleStatus?: ArticleStatus;
  grouping?: Grouping;
  groupNo?: string;
  tailOptions?: TailOptions;
  column?: ArticleColumn;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
}): string => {
  const queryParams: string[] = [];

  if (retailUnitCode) {
    queryParams.push(`ru_code=${retailUnitCode}`);
  }

  if (fiscalYear) {
    queryParams.push(`fiscal_year=${fiscalYear}`);
  }

  if (articleStatus) {
    queryParams.push(`article_status=${articleStatus}`);
  }

  if (grouping) {
    queryParams.push(`grouping=${grouping}`);
  }

  if (groupNo) {
    queryParams.push(`group_no=${groupNo}`);
  }

  if (column) {
    queryParams.push(`column=${column}`);
  }

  if (tailOptions?.probabilityOfBuying) {
    queryParams.push(`prob_of_buying=${toQueryParamList(tailOptions.probabilityOfBuying)}`);
  }

  if (tailOptions?.revenuePerReceipt) {
    queryParams.push(`rev_per_receipt=${toQueryParamList(tailOptions.revenuePerReceipt)}`);
  }

  if (tailOptions?.quantityGrowthRate) {
    queryParams.push(`qty_growth_rate=${toQueryParamList(tailOptions.quantityGrowthRate)}`);
  }

  if (tailOptions?.quantityPerReceipt) {
    queryParams.push(`qty_per_receipt=${toQueryParamList(tailOptions.quantityPerReceipt)}`);
  }

  if (fivePercentRankingTail) {
    queryParams.push(`five_percent_ranking_tail=true`);
  }

  if (tenPercentRankingTail) {
    queryParams.push(`ten_percent_ranking_tail=true`);
  }

  if (autoDetectRankingTail) {
    queryParams.push(`auto_detect_ranking_tail=true`);
  }

  return queryParams.join('&');
};
