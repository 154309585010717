import { buildQueryString } from '@/api/api.utils';
import config from '@/config';
import { useAPI } from '@/hooks/use-api.hook';
import type { ArticleColumn } from '@/types/article-column.type';
import type { ArticleFilter } from '@/types/article-filter.type';
import type { ArticleStatus } from '@/types/article-status.enum';
import type { Article } from '@/types/article.type';
import type { ChartDataEntry } from '@/types/chart-data-entry.interface';
import type { ChartGroupType } from '@/types/chart-group-type.enum';
import type { CleanGroup } from '@/types/clean-group.interface';
import type { Country } from '@/types/country.type';
import type { CreateUserSettingsRequestDTO } from '@/types/dto/create-user-settings-request-dto.interface';
import type { CreateUserViewCommentRequestDTO } from '@/types/dto/create-user-view-comment-request-dto.interface';
import type { CreateUserViewRequestDTO } from '@/types/dto/create-user-view-request-dto.interface';
import type { UpdateUserSettingsRequestDTO } from '@/types/dto/update-user-settings-request-dto.interface';
import type { UpdateUserViewCommentRequestDTO } from '@/types/dto/update-user-view-comment-request-dto.interface';
import type { UpdateUserViewRequestDTO } from '@/types/dto/update-user-view-request-dto.interface';
import { Grouping } from '@/types/grouping.enum';
import type { KPIArticle } from '@/types/kpi-article.interface';
import type { Language } from '@/types/language.enum';
import type { UserDetails } from '@/types/models/user-details.interface';
import type { UserSession } from '@/types/models/user-session.interface';
import type { UserSettings } from '@/types/models/user-settings.interface';
import type { UserViewChangeHistory } from '@/types/models/user-view-change-history.interface';
import type { UserViewCommentSeen } from '@/types/models/user-view-comment-seen.interface';
import type { UserViewComment } from '@/types/models/user-view-comment.interface';
import type { UserViewNotificationDetails } from '@/types/models/user-view-notification-details.interface';
import type { UserViewNotificationSeen } from '@/types/models/user-view-notification-seen.interface';
import type { UserView } from '@/types/models/user-view.interface';
import type { Option } from '@/types/option.interface';
import type { PaginationResponse } from '@/types/pagination-response.interface';
import type { Pagination } from '@/types/pagination.interface';
import type { RangeGraphSegmentArticle } from '@/types/range-graph-segment-article.interface';
import type { Search } from '@/types/search.interface';
import type { SliderRangeResponse } from '@/types/slider-range-response.interface';
import type { Sorting } from '@/types/sorting.interface';
import type { TailOptions } from '@/types/tail-options.interface';
import type { TotalCleanArticlesGrouping } from '@/types/total-clean-articles-grouping.interface';
import type { TotalCleanArticles } from '@/types/total-clean-articles.interface';
import type { WhatsNewDTO } from '@/types/models/whats-new.dto.interface';
import type { Spr } from '@/types/models/spr.interface';

const api = useAPI();

export const getI18n = (language: Language) => {
  const url = `/i18n/${language}.json`;
  return api.get<Record<string, string>>(url);
};

export const getWhatsNew = (): Promise<WhatsNewDTO> => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/whats-new`;
  return api.get<WhatsNewDTO>(url);
};

export const getRetailUnitCodes = (accessToken: string) => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/retail-unit-codes`;
  return api.get<string[]>(url, { customHeaders: { 'Access-Token': accessToken } });
};

export const getFiscalYears = (retailUnitCode: string) => {
  const query = buildQueryString({ retailUnitCode });
  const url = `${config.apiProductsEndpoint}/api/v1/products/fiscal-years?${query}`;
  return api.get<string[]>(url);
};

export const getFiscalYearsDescriptions = (retailUnitCode: string, fiscalYear: string) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear });
  const url = `${config.apiProductsEndpoint}/api/v1/products/fiscal-years-descriptions?${query}`;
  return api.get<string[]>(url);
};

export const getCountries = (): Promise<Country[]> => {
  return api.get<Country[]>(`/data/countries.json`);
};

export const getEmojis = (): Promise<string[][]> => {
  return api.get<string[][]>(`/data/emojis.json`);
};

export const getGroupingOptions = (retailUnitCode: string, fiscalYear: string, grouping: Grouping) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear, grouping });
  const url = `${config.apiProductsEndpoint}/api/v1/products/grouping-options?${query}`;

  return api.get<Option[]>(url);
};

export const getTotalCleanArticles = ({
  retailUnitCode,
  fiscalYear,
  groupings,
  tailOptions,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  groupings: TotalCleanArticlesGrouping[];
  tailOptions?: TailOptions;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    tailOptions,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/total-clean-articles?${query}`;

  return api.post<TotalCleanArticles[]>(url, groupings);
};

export const getKPIArticles = (retailUnitCode: string, fiscalYear: string) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear });
  const url = `${config.apiProductsEndpoint}/api/v1/products/kpi-articles?${query}`;

  return api.get<KPIArticle[]>(url);
};

export const getSliderRange = (retailUnitCode: string, fiscalYear: string, grouping: Grouping, groupNo?: string) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear, grouping, groupNo });
  const url = `${config.apiProductsEndpoint}/api/v1/products/slider-range?${query}`;
  return api.get<SliderRangeResponse>(url);
};

export const getGroups = ({
  retailUnitCode,
  fiscalYear,
  grouping,
  groupNo,
  tailOptions,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  grouping: Grouping;
  groupNo?: string;
  tailOptions?: TailOptions;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    grouping,
    groupNo,
    tailOptions,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/groups?${query}`;

  return api.get<CleanGroup[]>(url);
};

export const getChartsData = ({
  retailUnitCode,
  fiscalYear,
  chartGroupTypes,
  grouping,
  groupNo,
  tailOptions,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  chartGroupTypes: ChartGroupType[];
  grouping: Grouping;
  groupNo?: string;
  tailOptions?: TailOptions;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    grouping,
    groupNo,
    tailOptions,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/chart-data?${query}`;
  const chartGroupRequestUrls = chartGroupTypes.map(
    (chartGroupType) => `${url}&chart_group_type=${encodeURIComponent(chartGroupType)}`,
  );

  return Promise.all(chartGroupRequestUrls.map((url) => api.get<ChartDataEntry[]>(url)));
};

export const getTableArticles = ({
  retailUnitCode,
  fiscalYear,
  tailOptions,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  pagination,
  sorting,
  search,
  filters,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  tailOptions?: TailOptions;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
  pagination?: Pagination;
  sorting?: Sorting<ArticleColumn>;
  search?: Search<ArticleColumn>;
  filters?: ArticleFilter[];
  excludedArticlesIds?: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    tailOptions,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/articles?${query}`;

  return api.post<PaginationResponse<Article>>(url, {
    pagination,
    sorting,
    search,
    filters,
    excluded_articles_ids: excludedArticlesIds,
  });
};

export const getTableFilterOptions = ({
  retailUnitCode,
  fiscalYear,
  column,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  column: ArticleColumn;
}) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear, column });
  const url = `${config.apiProductsEndpoint}/api/v1/products/filter-options?${query}`;

  return api.get<Option<string>[]>(url);
};

export const requestExcelExport = ({
  retailUnitCode,
  fiscalYear,
  currency,
  currencyCode,
  articleFilters,
  tailOptions,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  grouping,
  groupNoPath,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  currency: string;
  currencyCode: string;
  articleFilters: ArticleFilter[];
  tailOptions?: TailOptions;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
  grouping: Grouping;
  groupNoPath: string[] | null;
}) => {
  const [hfbNo, paNo] = groupNoPath ?? [];
  const [functionalAreaNo, consumerFunctionNo, productFunctionNo] = groupNoPath ?? [];
  const filters: ArticleFilter[] = [
    ...articleFilters,
    ...(grouping === Grouping.HFB && hfbNo
      ? [
          {
            column: 'hfb_no',
            values: [hfbNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.HFB && paNo
      ? [
          {
            column: 'pa_no',
            values: [paNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.FUNCTIONAL_AREA && functionalAreaNo
      ? [
          {
            column: 'functional_area_name_no',
            values: [functionalAreaNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.FUNCTIONAL_AREA && consumerFunctionNo
      ? [
          {
            column: 'consumer_function_name_no',
            values: [consumerFunctionNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.FUNCTIONAL_AREA && productFunctionNo
      ? [
          {
            column: 'product_function_name_no',
            values: [productFunctionNo],
          } as ArticleFilter,
        ]
      : []),
  ];

  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    tailOptions,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/articles-excel?${query}`;

  return api.post<Blob>(url, { filters, currency, currencyCode }, { responseType: 'blob' });
};

export const createUserView = (dto: CreateUserViewRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view`;
  return api.post<UserView>(url, dto);
};

export const updateUserView = (dto: UpdateUserViewRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view`;
  return api.put<UserView>(url, dto);
};

export const deleteUserView = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view?id=${viewId}`;
  return api.delete<void>(url);
};

export const getSharedUserViews = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/shared/list`;
  return api.get<UserView[]>(url);
};

export const getOwnedUserViews = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/owned/list`;
  return api.get<UserView[]>(url);
};

export const getUserViewChangeHistories = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/history/list?view_id=${viewId}`;
  return api.get<UserViewChangeHistory[]>(url);
};

export const createUserViewComment = (dto: CreateUserViewCommentRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment`;
  return api.post<UserViewComment>(url, dto);
};

export const updateUserViewComment = (dto: UpdateUserViewCommentRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment`;
  return api.put<UserViewComment>(url, dto);
};

export const deleteUserViewComment = (viewId: string, sectionId: string, commentId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment?view_id=${viewId}&section_id=${sectionId}&comment_id=${commentId}`;
  return api.delete<void>(url);
};

export const getUserViewComments = (viewId: string, sectionId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment/list?view_id=${viewId}&section_id=${sectionId}`;
  return api.get<UserViewComment[]>(url);
};

export const getUserViewCommentsSeen = (viewId: string, sectionId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment/seen/list?view_id=${viewId}&section_id=${sectionId}`;
  return api.get<UserViewCommentSeen[]>(url);
};

export const getUserViewNotifications = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/notification/list?view_id=${viewId}`;
  return api.get<UserViewNotificationDetails[]>(url);
};

export const getUserViewNotificationsSeen = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/notification/seen/list?view_id=${viewId}`;
  return api.get<UserViewNotificationSeen[]>(url);
};

export const getRangeGraphSegmentArticles = ({
  retailUnitCode,
  fiscalYear,
  articleStatus,
  grouping,
  groupNo,
  tailOptions,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
}: {
  articleStatus: ArticleStatus;
  retailUnitCode: string;
  fiscalYear: string;
  grouping: Grouping;
  groupNo?: string;
  tailOptions?: TailOptions;
  fivePercentRankingTail?: boolean;
  tenPercentRankingTail?: boolean;
  autoDetectRankingTail?: boolean;
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    articleStatus,
    grouping,
    groupNo,
    tailOptions,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/groups/segment?${query}`;

  return api.get<RangeGraphSegmentArticle[]>(url);
};

export const getUserSessions = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/session/list`;
  return api.get<UserSession[]>(url);
};

export const createUserSettings = (dto: CreateUserSettingsRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/settings`;
  return api.post<UserSettings>(url, dto);
};

export const updateUserSettings = (dto: UpdateUserSettingsRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/settings`;
  return api.put<UserSettings>(url, dto);
};

export const getUserSettings = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/settings`;
  return api.get<UserSettings>(url);
};

export const getUsersDetailsByOwnerNameOrEmail = (ownerNameOrEmail: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/user-details/search?owner_name_or_email=${ownerNameOrEmail}`;
  return api.get<UserDetails[]>(url);
};

export const getUsersDetails = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/user-details/list`;
  return api.get<UserDetails[]>(url);
};

export const getSPRs = (retailUnitCode: string) => {
  const query = buildQueryString({ retailUnitCode });
  const url = `${config.apiProductsEndpoint}/api/v1/products/sprs?${query}`;
  return api.get<Spr[]>(url);
};
