import { createRouter, createWebHistory } from 'vue-router';
import { defaultQueryParamsGuard, isAuthenticatedGuard, isUnauthenticatedGuard, retailUnitCodeGuard } from './guards';
import { RoutePath } from './types';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: RoutePath.SIGN_IN,
      beforeEnter: [isUnauthenticatedGuard],
      component: () => import('@/pages/PageSignIn/PageSignIn.vue'),
    },
    {
      path: RoutePath.MARKET_SELECTION,
      beforeEnter: [isAuthenticatedGuard],
      component: () => import('@/pages/PageMarketSelection/PageMarketSelection.vue'),
    },
    {
      path: `${RoutePath.RANGE}/:viewId?`,
      component: () => import('@/pages/PageRange/PageRange.vue'),
      beforeEnter: [isAuthenticatedGuard, retailUnitCodeGuard, defaultQueryParamsGuard('range')],
    },
    {
      path: `${RoutePath.PROJECTS}/:id?`,
      component: () => import('@/pages/PageMyProjects/PageMyProjects.vue'),
      beforeEnter: [isAuthenticatedGuard, retailUnitCodeGuard, defaultQueryParamsGuard('projects')],
    },
    {
      path: `${RoutePath.ABOUT}/:id?`,
      component: () => import('@/pages/PageAbout/PageAbout.vue'),
      beforeEnter: [isAuthenticatedGuard],
    },
    {
      path: RoutePath.NOT_AUTHORIZED,
      beforeEnter: [isUnauthenticatedGuard],
      component: () => import('@/pages/PageNotAuthorized/PageNotAuthorized.vue'),
    },
    {
      path: RoutePath.SIGNED_OUT,
      beforeEnter: [isUnauthenticatedGuard],
      component: () => import('@/pages/PageSignedOut/PageSignedOut.vue'),
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '/',
    },
  ],
});

export default router;
