import type { UserSettingsState } from './types/models/user-settings-state.interface';

export const JIRA_SERVICE_DESK_URL = 'https://jira.digital.ingka.com/servicedesk/customer/portal/424';
export const VIEW_OPTION_SEPARATOR = ' - ';
export const BREAKPOINTS = {
  /**
   * 480px
   */
  xs: 480,
  /**
   * 640px
   */
  sm: 640,
  /**
   * 768px
   */
  md: 768,
  /**
   * 1024px
   */
  lg: 1024,
  /**
   * 1280px
   */
  xl: 1280,
  /**
   * 1536px
   */
  '2xl': 1536,
};

export const SCROLL_DEBOUNCE_MS = 30;
export const HEARTBEAT_DEBOUNCE_MS = 1000;
export const DEFAULT_SNACKBAR_DURATION = 8000;
export const NOT_AVAILABLE_PLACEHOLDER = 'N/A';

export const DEFAULT_USER_SETTINGS_STATE: UserSettingsState = {
  tableExcludedArticlesIds: [],
  tablePinnedColumns: [],
  tableHiddenColumns: [],
  tableOrderedColumns: [],
  tableArticleStatusValues: [],
  tableCCLValues: [],
  tableStyleGroupValues: [],
  tablePriceLevelValues: [],
  tableProductNameValues: [],
  tablePage: 1,
  tableItemsPerPage: 10,
  tableOrderBy: 'Item_Name',
  tableDesc: false,
  tableSearchColumn: 'Item_Number',
  tableSearchValue: '',
  tableColoring: {},
};
