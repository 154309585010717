<template>
  <AppNotificationsContainer />

  <AppLoadingOverlay v-if="loading || guardsLoading" />
  <router-view v-if="!loading"></router-view>
</template>

<script lang="ts" setup>
  import AppNotificationsContainer from '@/components/AppNotificationsContainer/AppNotificationsContainer.vue';
  import { useIDTokenStore } from '@/store/id-token/id-token.store';
  import { useAuth0 } from '@auth0/auth0-vue';
  import { debounce } from 'lodash';
  import { onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import AppLoadingOverlay from './components/AppLoadingOverlay/AppLoadingOverlay.vue';
  import { HEARTBEAT_DEBOUNCE_MS } from './constants';
  import { useUserSettingsStore } from './store/user-settings/user-settings.store';
  import { useUserViewChangeHistoryStore } from './store/user-view/user-view-change-history.store';
  import { useUserViewCommentSeenStore } from './store/user-view/user-view-comment-seen.store';
  import { useUserViewCommentStore } from './store/user-view/user-view-comment.store';
  import { useUserViewNotificationSeenStore } from './store/user-view/user-view-notification-seen.store';
  import { useUserViewNotificationStore } from './store/user-view/user-view-notification.store';
  import { useUserViewStore } from './store/user-view/user-view.store';
  import { useWebSocketsStore } from './store/web-sockets/web-sockets.store';

  const { idTokenClaims, user } = useAuth0();
  const idTokenStore = useIDTokenStore();
  const route = useRoute();
  const router = useRouter();
  const userViewStore = useUserViewStore();
  const webSocketsStore = useWebSocketsStore();
  const userSettingsStore = useUserSettingsStore();
  const userViewCommentStore = useUserViewCommentStore();
  const userViewCommentSeenStore = useUserViewCommentSeenStore();
  const userViewNotificationStore = useUserViewNotificationStore();
  const userViewChangeHistoryStore = useUserViewChangeHistoryStore();
  const userViewNotificationSeenStore = useUserViewNotificationSeenStore();

  const loading = ref<boolean>(true);
  const guardsLoading = ref<boolean>(false);

  const trackUserActivity = debounce(() => {
    webSocketsStore.sendHeartbeat();
  }, HEARTBEAT_DEBOUNCE_MS);

  onMounted(async () => {
    await router.isReady();

    if (!idTokenClaims.value?.__raw) {
      loading.value = false;
      return;
    }

    const userId = user.value!.sub!;
    const userEmail = user.value!.email!;

    webSocketsStore.initWebSocketChannels(userId, userEmail, idTokenClaims.value.__raw, [
      userViewStore,
      userViewCommentStore,
      userViewCommentSeenStore,
      userViewNotificationStore,
      userViewNotificationSeenStore,
      userViewChangeHistoryStore,
    ]);

    // Attach event listeners for user activity tracking
    ['mousemove', 'keypress', 'click'].forEach((event) => window.addEventListener(event, trackUserActivity));

    await userSettingsStore.fetchSettings();

    loading.value = false;
  });

  watch(idTokenClaims, () => {
    const idToken = idTokenClaims.value?.__raw;
    if (idToken) idTokenStore.setToken(idToken);
  });

  watch(route, () => {
    if (loading.value) {
      return;
    }

    // Start updating last path once initial loading has completed
    webSocketsStore.updateLastRoutePath(route.path);
  });

  router.beforeEach((to, from) => {
    if (to.path !== from.path) {
      guardsLoading.value = true;
    }
  });

  router.afterEach(() => {
    guardsLoading.value = false;
  });

  router.onError(() => {
    guardsLoading.value = false;
  });
</script>
