export type MenuAboutItem = {
  id: MenuAboutSections;
  label: string;
};

export enum MenuAboutSections {
  WORK_WITH_KPIS = 'work-with-kpis',
  RECOMMENDED_START = 'recommended-start',
  AUTOMATIC_TAIL_DETECTION = 'automatic-tail-detection',
  PROBABILITY_OF_BUYING = 'probability-of-buying',
  REVENUE_PER_RECEIPT = 'revenue-per-receipt',
  QUANTITY_GROWTH_RATE = 'quantity-growth-rate',
  QUANTITY_PER_RECEIPT = 'quantity-per-receipt',
  DATA_SOURCES = 'data-sources',
  FUNCTIONAL_GROUPING = 'functional-grouping',
  OTHER_DEFINITIONS = 'other-definitions',
}
